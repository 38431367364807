export const formatRouletteHistory = (history) => {
  return history.map((h) => {
    let icon = "bonus";
    if (h.outcome >= 1 && h.outcome <= 7) icon = "ct";
    else if (h.outcome >= 8 && h.outcome <= 14) icon = "t";
    return {
      id: h.id,
      icon,
      hash: h.hash,
    };
  });
};
