import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index.js";
import cookie from "vue-cookies";

import Fair from "@/views/Home/Fair.vue";
import Affiliates from "@/views/Home/Affiliates.vue";

import Profile from "@/views/Profile/Main.vue";
import Admin from "@/views/Admin/Main.vue";

import Roulette from "@/views/Roulette/Main.vue";
import Coinflip from "@/views/Coinflip/Main.vue";
import Minesweeper from "@/views/Minesweeper/Main.vue";
import Cases from "@/views/Cases/Main.vue";
import Case from "@/views/Cases/Case.vue";

const checkUserLoggedIn = async (to, from, next) => {
  // if user already logged in, just continue to the page
  if (store.state.loggedin) return next();

  // if the user has the session token cookie, proceeds to check if the cookie is in db, if it is, return with user data info
  if (cookie.get("session_token")) {
    let user = await store.dispatch(
      "get_user_logged_in",
      cookie.get("session_token")
    );
    if (!user) return next();

    // if user not found by session token, invalidate the token from cookies
    if (user.status === 404) {
      cookie.remove("session_token");
      return next();
    }

    // other statuses, just go next
    if (user.status) return next();

    store.dispatch("set_user_logon", user);
    return next();
  }

  next();
};

const checkUserAdmin = (to, from, next) => {
  if (store.state.user.rank !== 100) return next('/roulette')
  next()
}

const redirectMainPage = (to, from, next) => {
  next("/roulette");
};

const setCurrentCase = (to, from, next) => {
  const case_target_id = to.params.case_id;
  const case_id = parseInt(case_target_id.split('-')[0])

  if (store.state.website.games.cases.boxes.filter(c => c.tid === case_target_id).length === 0)
    return next('/cases')
  if (store.state.website.games.cases.current_case === null)
    store.dispatch('set_case', case_id)

  next();
};

const routes = [
  {
    path: "/",
    name: "Home",
    beforeEnter: [redirectMainPage, checkUserLoggedIn],
  },
  {
    path: "/fair",
    name: "Fair",
    component: Fair,
    beforeEnter: [checkUserLoggedIn],
  },
  {
    path: "/affiliates",
    name: "Affiliates",
    component: Affiliates,
    beforeEnter: [checkUserLoggedIn],
  },
  {
    path: "/roulette",
    name: "roulette",
    component: Roulette,
    beforeEnter: [checkUserLoggedIn],
  },
  {
    path: "/coinflip",
    name: "coinflip",
    component: Coinflip,
    beforeEnter: [checkUserLoggedIn],
  },
  {
    path: "/mines",
    name: "mines",
    component: Minesweeper,
    beforeEnter: [checkUserLoggedIn],
  },
  {
    path: "/cases",
    name: "cases",
    component: Cases,
    beforeEnter: [checkUserLoggedIn],
  },
  {
    path: "/case/:case_id",
    name: "case",
    component: Case,
    beforeEnter: [checkUserLoggedIn, setCurrentCase],
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    beforeEnter: [checkUserLoggedIn]
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    beforeEnter: [checkUserLoggedIn, checkUserAdmin]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
