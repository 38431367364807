<template>
  <div class="gm-wrapper text-white affs">
    <div class="w-full">
      <h2>Deposits</h2>
      <br>
      <div class="atabel">
        <div class="arow">Transaction ID</div>
        <div class="arow">User ID</div>
        <div class="arow">User Name</div>
        <div class="arow">Amount</div>
        <div class="arow">State</div>
        <div class="arow">Actions</div>
      </div>
      <br>
      <div class="atabel" v-for="(d, index) in deposits" :key="index">
        <div class="arow">{{ d.id }}</div>
        <div class="arow">{{ d.userId }}</div>
        <div class="arow">{{ d.userName }}</div>
        <div class="arow">{{ d.amount }}</div>
        <div class="arow">{{ d.state }}</div>
        <div class="arow" v-if="d.state === 0">
          <button class="deposit-btn" @click="credit(d.id)">Credit</button>
          <button class="withdraw-btn" @click="cancel(d.id, 'deposits')">Cancel</button>
        </div>
        <div class="arow" v-else></div>
      </div>
    </div>
    <div class="w-full">
      <h2>Withdraws</h2>
      <br>
      <div class="atabel">
        <div class="arow">Transaction ID</div>
        <div class="arow">User ID</div>
        <div class="arow">User Name</div>
        <div class="arow">Amount</div>
        <div class="arow">State</div>
        <div class="arow">Actions</div>
      </div>
      <br>
      <div class="atabel" v-for="(w, index) in withdraws" :key="index">
        <div class="arow">{{ w.id }}</div>
        <div class="arow">{{ w.userId }}</div>
        <div class="arow">{{ w.userName }}</div>
        <div class="arow">{{ w.amount }}</div>
        <div class="arow">{{ w.state }}</div>
        <div class="arow" v-if="w.state === 0">
          <button class="deposit-btn" @click="process(w.id)">Processed</button>
          <button class="withdraw-btn" @click="cancel(w.id, 'withdraws')">Cancel</button>
        </div>
        <div class="arow" v-else></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminMainComp",
  methods: {
    credit(rid) {
      this.$store.dispatch('admin', {
        type: 'credit',
        rid
      })
    },
    cancel(rid, tip) {
      this.$store.dispatch('admin', {
        type: 'cancel',
        rid,
        tip
      })
    },
    process(rid) {
      this.$store.dispatch('admin', {
        type: 'process',
        rid
      })
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    deposits() {
      return this.$store.state.admin.deposits;
    },
    withdraws() {
      return this.$store.state.admin.withdraws;
    }
  },
  mounted() {
    this.$store.dispatch('admin_runescape')
  }
};
</script>