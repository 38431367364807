<template>
  <div class="gm-wrapper case_box">
    <div class="top">
      <div>
        <div @click="go_back">Go back</div>
      </div>
      <div>{{ curr_case.name }}</div>
    </div>
    <div class="middle">
      <div class="cases-roller">
        <div class="cases-roller-background"></div>
        <div class="cases-roller-holder">
          <div class="cases-roller-container" style="margin-left: 0px;">
            <div class="cases-user-card" :data-card-id="index" v-for="(user, index) in userss" :key="index">
              <img :src="user" />
            </div>
          </div>
        </div>
      </div>
      <div class="mbottom">
        <div class="price">
          <div>
            <span>Price</span>
            <span><span class="text-yellow-500"><font-awesome-icon class="gold"
                  icon="fa-solid fa-coins" /></span>&nbsp;{{ this.curr_case.price }}</span>
          </div>
        </div>
        <div class="count">
          <div>
            <div @click="changeCaseOpening(c)" v-for="(c, index) in max_opening_cases" :key="index">
              {{ parseInt(c) }}
            </div>
          </div>
          <div class="open_btn">Open {{ opening_cases > 1 ? opening_cases : '' }}</div>
        </div>
        <div class="btns">
          <div>
            <input id="default-checkbox" type="checkbox" value=""
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
            <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Quick Spin</label>
          </div>
          <div class="demo_btn">
            Demo
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/cases.css"

export default {
  name: "CasesCasePage",
  data() {
    return {
      userss: [],
      max_opening_cases: 4,
      opening_cases: 1
    }
  },
  methods: {
    go_back() {
      this.$router.push('/cases')
    },
    changeCaseOpening(count) {
      this.opening_cases = count
    }
  },
  computed: {
    env() {
      return this.$store.state.env;
    },
    curr_case() {
      return this.$store.state.website.games.cases.boxes.filter(i => i.id === this.$store.state.website.games.cases.current_case)[0];
    }
  },

  mounted() {
    // MINIMUM: 12000
    // MAXIMUM: 12085
    // BETWEEN 12000 AND 12085 - NEGATIVE


    const images = {
      1: 'https://static.rbxgold.com/media/larp.png',
      2: 'https://static.rbxgold.com/media/shaggy-pants.png',
      3: 'https://static.rbxgold.com/media/troublemaker.png',
      4: 'https://static.rbxgold.com/media/knight-of-the-stygian-abyss.png'
    }

    for (let i = 0; i < 131; i++) {
      let rng = Math.floor((Math.random() * (5 - 1)) + 1);
      this.userss.push(images[rng])
    }
  }
};
</script>