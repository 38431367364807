<template>
  <Modal :show="show" :modal="modal">
    <template v-slot:header>
      <h3 class="text-xl font-semibold text-white">Free Coins</h3>
    </template>
    <template v-slot:body>
      <input
        class="flex m-auto bg-gray-200 appearance-none border-2 border-gray-200 rounded w-2/5 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
        type="text" placeholder="e.g: RuneEmpire" v-model="free_coins" />
    </template>
    <template v-slot:footer>
      <button type="button"
        class="flex justify-center items-center mt-5 text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-green-600 hover:bg-green-700 focus:ring-green-800"
        @click="use_free_code">
        Use Code
      </button>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "FreeCoinsModal",
  components: {
    Modal,
  },
  data() {
    return {
      free_coins: "",
      modal: "freecoins",
    };
  },
  methods: {
    use_free_code() {
      this.$store.dispatch("user_use_free_code", this.free_coins);
    },
  },
  watch: {
    show() {
      if (!this.$store.state.modals[this.modal]) {
        this.free_coins = "";
      }
    },
  },
  computed: {
    show() {
      return this.$store.state.modals[this.modal];
    },
  },
};
</script>
