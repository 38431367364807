<template>
  <Modal :show="show" :modal="modal">
    <template v-slot:header>
      <h3 class="text-xl font-semibold text-white">
        Terms of Service
      </h3>
    </template>
    <template v-slot:body>
      <div class="font-bold text-white">RuneEmpire Terms of Service</div>
      <p class="text-white">
        1. <strong>Purpose</strong>: RuneEmpire is intended solely for entertainment purposes.
      </p>
      <p class="text-white">
        2. <strong>Age Requirement</strong>: Users must be at least 18 years old to access any services provided by RuneEmpire.
      </p>
      <p class="text-white">
        3. <strong>In-Game Wealth</strong>: Any gold acquired through games hosted by RuneEmpire is for the purpose of enhancing in-game wealth only.
      </p>
      <p class="text-white">
        4. <strong>Usage Restrictions</strong>: All gold obtained is exclusively for Old School Runescape© gameplay. Any other use will result in immediate termination of your RuneEmpire account and forfeiture of any current Old School Runescape© balance.
      </p>
      <p class="text-white">
        5. <strong>Liability</strong>: RuneEmpire assumes no liability for any loss, whether monetary or virtual.
      </p>
      <p class="text-white">
        6. <strong>User Responsibility</strong>: It is the user's responsibility to read the Terms of Service before accessing the site on each visit.
      </p>
      <p class="text-white">
        7. <strong>Wallet Balance</strong>: Your RuneEmpire Wallet balance is a placeholder representing the current value of Old School Runescape© gold held on your behalf.
      </p>
      <p class="text-white">
        8. <strong>Finality of Transactions</strong>: All bets and transactions are final.
      </p>
      <p class="text-white">
        9. <strong>Legal Compliance</strong>: If using RuneEmpire is illegal in your jurisdiction, you are prohibited from using the site.
      </p>
      <p class="text-white">
        10. <strong>Agreement</strong>: If you do not agree with any of our Terms of Service, you must leave RuneEmpire immediately.
      </p>
      <div class="font-bold text-white">Code of Conduct</div>
      <p class="text-white">
        1. <strong>Respectful Interaction</strong>: Users must not grief, harass, bully, or crybully other users or site staff. The goal is to foster a fun and cooperative environment.
      </p>
      <p class="text-white">
        2. <strong>Cooperation</strong>: Users are expected to cooperate with moderators and administrators.
      </p>
      <p class="text-white">
        3. <strong>Integrity</strong>: Stealing or scamming is strictly prohibited.
      </p>
      <p class="text-white">
        4. <strong>Communication</strong>: Users must refrain from posting spam or offensive messages.
      </p>
      <p class="text-white">
        5. <strong>Assistance</strong>: Users are encouraged to help new members navigate the site and get settled.
      </p>
      <p class="text-white">
        6. <strong>Conflict Resolution</strong>: If you do not get along with another user, use the /ignore chat command rather than engaging in conflict.
      </p>
      <p class="text-white">
        7. <strong>Prohibited Transactions</strong>: Selling anything via RuneEmpire is not allowed. RuneEmpire is not a trading platform.
      </p>
      <p class="text-white">
        8. <strong>Privacy</strong>: Do not publish personally identifiable information about yourself or other RuneEmpire users.
      </p>
      <div class="font-bold text-white">Defect Policy</div>
      <p class="text-white">
        1. <strong>Reporting Issues</strong>: If you discover any bugs or technical problems on RuneEmpire, please report them promptly.
      </p>
      <p class="text-white">
        2. <strong>Support</strong>: If you believe your game experienced a bug or malfunction, contact support for assistance.
      </p>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "TosModalComp",
  components: {
    Modal,
  },
  data() {
    return {
      modal: "tos",
    };
  },
  methods: {
    showTos() {
      this.$store.dispatch("set_modal", { modal: "login", value: false });
      this.$store.dispatch("set_modal", { modal: "tos", value: true });
    },
  },
  computed: {
    show() {
      return this.$store.state.modals[this.modal];
    },
  },
};
</script>