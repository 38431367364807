/**
 * @description This method makes so that a case url will be formatted in a nice way
 * 
 * Example:
 * Case ID: 10283
 * Case name: Best 99% Profit case
 * 
 * Formatted Case url: "/case/10283-best-99-percent-profit-case"
 */
const createCaseTargetID = (c) => {
  const name = c.name.toLowerCase().split(' ').join('-').replace('%', '-percent').replace(/[^A-Za-z0-9-.]/g, '')
  const target_id = c.id + '-' + name;
  c.tid = target_id;
  return c;
}

module.exports = {
  createCaseTargetID
}