<template>
  <div class="chat bg-gray-800"> <!-- Removed fixed width class -->
    <div class="header flex flex-row justify-between p-3">
      <div class="font-bold text-white flex flex-row items-center">
        <font-awesome-icon class="online h-3" icon="fa-solid fa-circle" />
        <span>&nbsp;{{ players }}</span>
      </div>
      <div class="font-bold text-gray-300 uppercase">Chat</div>
      <div
        class="text-gray-500 cursor-pointer hover:text-green-500"
        @click="openRules"
      >
        <font-awesome-icon icon="fa-solid fa-file-circle-exclamation" />
      </div>
    </div>
    <div class="body relative z-10"> <!-- Added relative positioning and z-index -->
      <div class="chat_fade absolute z-50"></div>
      <div ref="chat_messages" class="messages">
        <div
          class="message"
          v-for="(msg, index) in messages"
          :key="index"
          v-html="format_chat_message(msg)"
        ></div>
      </div>
      <div class="controls flex flex-row justify-between items-center p-5">
        <div class="flex-grow">
          <input
            @keydown.enter="send_message"
            v-model="msginput"
            class="w-full cinput chat_input transition-colors bg-gray-700 hover:bg-gray-800"
            type="text"
            placeholder="type a message here.."
          />
        </div>
        <div
          @click="send_message"
          class="btn hover:bg-green-400 text-gray-400 hover:text-gray-800 transition-colors cursor-pointer w-8 h-8 flex items-center"
        >
          <font-awesome-icon
            class="transition-colors m-auto"
            icon="fa-solid fa-paper-plane"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/chat.css";

export default {
  name: "ChatComp",
  data() {
    return {
      msginput: "",
    };
  },
  methods: {
    scroll_chat() {
      this.$refs.chat_messages.scrollTop = 9999999;
    },
    send_message() {
      this.$store.dispatch("send_message", this.msginput);
      this.msginput = "";
    },
    format_chat_message(msg) {
      let rank_formats = {
        0: {
          rank: "empty",
          prefix: "",
        },
        1: {
          rank: "mod",
          prefix: "Mod",
        },
        100: {
          rank: "admin",
          prefix: "Admin",
        },
        101: {
          rank: "dev",
          prefix: "Dev",
        },
      };

      setTimeout(() => {
        this.scroll_chat();
      }, 100);

      return `
        <div class="flex flex-row items-center mb-3">
          <div class="w-1/6">
            <img class="user-chat" src="${msg.avatar}" alt="User Avatar"/>
          </div>
          <div class="flex flex-col items-start w-5/6 user-${
            rank_formats[msg.rank].rank
          } user-left">
            <div class="align-left">
              <span class="text-sm text-gray-200"><span>${
                rank_formats[msg.rank].prefix
              }${msg.rank > 0 ? "&nbsp;" : ""}</span>${msg.username}</span>
              <span style="font-size: 11px;" class="ml-2 text-gray-600">${this.formatTime(
                msg.time
              )}</span>
            </div>
            <div class="text-gray-400 text-sm">
              ${msg.content}
            </div>
          </div>
        </div>
      `;
    },
    formatTime(time_passed) {
      let time = Date.now() - time_passed;
      let formattedTime = new Date(time).toLocaleTimeString();
      formattedTime = formattedTime.split(" ");
      formattedTime = formattedTime[0].split(":");
      return `${
        formattedTime[0] < 10 ? `0${formattedTime[0]}` : formattedTime[0]
      }:${
        formattedTime[1] < 10 ? `0${formattedTime[1]}` : formattedTime[1]
      }`;
    },
    openRules() {
      this.$store.dispatch("set_modal", {
        modal: "rules",
        value: true,
      });
    },
  },
  computed: {
    messages() {
      return this.$store.state.website.chat.messages;
    },
    players() {
      return this.$store.state.website.players;
    },
  },
  created() {
    // handle the event when the window resize > to scroll the chat to top automatically
    window.addEventListener("resize", this.scroll_chat);

    // handle the event when the window gets focused > to scroll the chat to top automatically
    window.addEventListener("focus", this.scroll_chat);
  },
  unmounted() {
    // handle the event when the window resize > to scroll the chat to top automatically
    window.removeEventListener("resize", this.scroll_chat);

    // handle the event when the window gets focused > to scroll the chat to top automatically
    window.removeEventListener("focus", this.scroll_chat);
  },
  mounted() {
    this.scroll_chat();
  },
};
</script>