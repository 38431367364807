<template>
	<div class="gm-wrapper cases">
		<div class="sorting">
			<div class="sorts">
				<div @click="sortChange(filter)" :class="sort.selected === filter ? 'sort active' : 'sort'"
					v-for="(filter, index) in sort.filters" :key="index">
					{{ filter }}
				</div>
			</div>
			<div class="others">
				<div>
					<span>Sort by:</span>
					<select @change="sortSecondary" v-model="sort.secondary">
						<option v-for="(filter, index) in sort.secondary_filters" :value="filter.value" :key="index">{{
						filter.name
					}}</option>
					</select>
				</div>
				<div>
					<span><font-awesome-icon icon="fa-solid fa-magnifying-glass" /></span>
					<input type="text" placeholder="Type box name here.." v-model="sort.text">
				</div>
			</div>
		</div>
		<div class="boxes">
			<div class="box" v-for="(c, i) in cases" :key="i" @click="pickCase(c)">
				<img :src="env.assets_url + c.image" :alt="c.name" />
				<span>{{ c.name }}</span>
				<span>
					<div class="text-yellow-500">
						<font-awesome-icon icon="fa-solid fa-coins" />
					</div>&nbsp;{{ c.price }}
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import "@/assets/css/cases.css"

export default {
	name: "CasesMain",
	data() {
		return {
			sort: {
				text: "",
				selected: 'All',
				filters: [
					'All',
					'Crazy',
					'CS2',
					'Battle'
				],
				secondary_filters: [
					{
						name: 'Recommended',
						value: 'recommended'
					},
					{
						name: 'Most expensive first',
						value: 'most_expensive_first'
					},
					{
						name: 'Cheapest first',
						value: 'cheapest_first'
					},
					{
						name: 'Latest first',
						value: 'latest_first'
					}
				],
				secondary: "recommended"
			}
		}
	},
	methods: {
		sortChange(filter) {
			this.sort.selected = filter;
		},
		sortSecondary(event) {
			this.sort.text = ''
			this.sort.secondary = event.target.value;
		},
		pickCase(c) {
			this.$store.dispatch('set_case', c.id)
			this.$router.push(`/case/${c.tid}`)
		}
	},
	computed: {
		env() {
			return this.$store.state.env;
		},
		cases() {
			let boxes = this.$store.state.website.games.cases.boxes
			if (this.sort.text.length > 0) {
				boxes = boxes.filter(box => box.name.toLowerCase().indexOf(this.sort.text) > -1)
			}

			if (this.sort.secondary !== 'recommended') {
				// switch (this.sort.secondary) {
				//     case ''
				// }
			}

			return boxes;
		}
	}
};
</script>