<template>
  <div class="gm-wrapper coinflip">
    <div class="bet-info flex flex-col">
      <div class="b-text">Bet Amount:</div>
      <div class="flex betting-input">
        <div class="text-yellow-500"><font-awesome-icon icon="fa-solid fa-coins" /></div><input v-model="bet_amount"
          type="number" placeholder="Enter bet amount...">
      </div>
    </div>
    <div class="flex flex-col">
      <div class="b-text">Choose side:</div>
      <div class="flex">
        <img src="" alt="">
        <img src="" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/coinflip.css"

export default {
  name: "HomeMain",
  data() {
    return {
      bet_amount: 0
    }
  },
  computed: {
    game() {
      return this.$store.state.website.games.coinflip;
    },
  }
};
</script>
