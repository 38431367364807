<template>
  <div class="gm-wrapper">
    <div class="roulette-wrapper">
      <div class="roulette-fade-left"></div>
      <div class="roulette-fade-right"></div>
      <div :class="selectorClass"></div>
      <div :class="rollingInClass">
        <div v-if="roulette_connected">ROLLING</div>
        <div v-if="roulette_connected" v-html="roulette_seconds"></div>
        <div v-if="!roulette_connected">CONNECTING..</div>
      </div>
      <div :style="roulette_style" :class="getWheelClass">
        <Wheel />
      </div>
    </div>
    <div
      class="roulette-betting flex flex-row items-center justify-center mt-5"
    >
      <div class="flex flex-row">
        <div class="text-gray-400 font-bold uppercase">previous rolls</div>
        <div class="flex flex-row ml-5">
          <transition
            name="fade"
            mode="out-in"
            class="ml-2"
            v-for="(item, index) in roulette_history"
            :key="index"
          >
            <img
              class="w-6 h-6"
              :src="roulette_icon(item.icon)"
              alt="history icon"
            />
          </transition>
        </div>
      </div>
      <div class="flex flex-row items-center ml-5">
        <div class="text-gray-400 font-bold uppercase">last 100</div>
        <div class="flex flex-row ml-5 text-white">
          <div class="ml-2 flex flex-row">
            <img
              class="w-6 h-6"
              :src="roulette_icon('ct')"
              alt="history icon"
            />
            <span class="ml-1" v-html="roulette_lastrolls.ct"></span>
          </div>
          <div class="ml-2 flex flex-row">
            <img
              class="w-6 h-6"
              :src="roulette_icon('bonus')"
              alt="history icon"
            />
            <span class="ml-1" v-html="roulette_lastrolls.bonus"></span>
          </div>
          <div class="ml-2 flex flex-row">
            <img class="w-6 h-6" :src="roulette_icon('t')" alt="history icon" />
            <span class="ml-1" v-html="roulette_lastrolls.t"></span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="roulette-betting-controls flex flex-row items-center justify-center"
    >
      <div class="flex betting-input">
        <div class="text-yellow-500">
          <font-awesome-icon icon="fa-solid fa-coins" />
        </div>
        <input v-model="bet" type="number" placeholder="Enter bet amount..." />
      </div>
      <div class="flex flex-row controls-btns">
        <div @click="action('clear')">CLEAR</div>
        <div @click="action('+10')">+10</div>
        <div @click="action('+100')">+100</div>
        <div @click="action('+1000')">+1K</div>
        <div @click="action('+10000')">+10K</div>
        <div @click="action('1/2')">1/2</div>
        <div @click="action('x2')">x2</div>
        <div @click="action('max')">MAX</div>
      </div>
    </div>
    <div
      class="roulette-secret flex justify-center items-center mt-5"
      v-if="roulette_secret"
    >
      <small class="text-gray-600"
        >Round Secret: {{ roulette_secret }} (#{{ roulette_id }})</small
      >
    </div>
    <div
      class="roulette-bet-buttons mt-5 w-10/12 m-auto flex flex-row justify-between items-center text-gray-100"
    >
      <div class="bet-data">
        <div
          @click="place_bet('ct')"
          class="place-bet transition-colors cursor-pointer hover:text-blue-400 hover:bg-slate-700/40 flex flex-row w-full justify-between items-center h-full"
        >
          <div class="flex flex-row justify-center items-center ml-5">
            <img
              class="w-8 h-8"
              :src="roulette_icon('ct')"
              alt="Place Bet Icon"
            />
            <div class="uppercase font-bold ml-2">place bet</div>
          </div>
          <div class="mr-5 text-gray-400">WIN 2x</div>
        </div>
      </div>
      <div class="bet-data">
        <div
          @click="place_bet('bonus')"
          class="place-bet transition-colors cursor-pointer hover:text-blue-400 hover:bg-slate-700/40 flex flex-row w-full justify-between items-center h-full"
        >
          <div class="flex flex-row justify-center items-center ml-5">
            <img
              class="w-8 h-8"
              :src="roulette_icon('bonus')"
              alt="Place Bet Icon"
            />
            <div class="uppercase font-bold ml-2">place bet</div>
          </div>
          <div class="mr-5 text-gray-400">WIN 14x</div>
        </div>
      </div>
      <div class="bet-data">
        <div
          @click="place_bet('t')"
          class="place-bet transition-colors cursor-pointer hover:text-blue-400 flex flex-row w-full justify-between items-center h-full"
        >
          <div class="flex flex-row justify-center items-center ml-5">
            <img
              class="w-8 h-8"
              :src="roulette_icon('t')"
              alt="Place Bet Icon"
            />
            <div class="uppercase font-bold ml-2">place bet</div>
          </div>
          <div class="mr-5 text-gray-400">WIN 2x</div>
        </div>
      </div>
    </div>
    <div
      class="players-betting-data mt-5 w-10/12 m-auto flex flex-row justify-between text-gray-100"
    >
      <div class="bets-info bet-data flex flex-col bg-slate-800 mt-1 h-full">
        <div class="b-total flex flex-row justify-between items-center">
          <div class="flex flex-row items-center justify-center">
            <div class="bet-icon hidden">
              <img
                class="w-6 h-6"
                src="@/assets/images/roulette/coin-ct.png"
              />&nbsp;&nbsp;&nbsp;
            </div>
            {{ roulette_bets_info.ct.total }} Bets Total
          </div>
          <div class="flex flex-row">
            <div class="text-orange-400">
              <font-awesome-icon icon="fa-solid fa-coins" />
            </div>
            <span class="ml-1">{{ roulette_bets_info.ct.value }}</span>
          </div>
        </div>
        <div class="b-players flex flex-col">
          <div
            v-for="(bet, index) in ct_bets"
            :key="index"
            class="flex flex-row items-center justify-between"
          >
            <div class="flex flex-row items-center">
              <img class="w-6 h-6 user-avatar" :src="bet.avatar" alt="User Avatar" />
              <span class="cmt-6">{{ bet.name }}</span>
            </div>
            <div class="cmt-6">{{ bet.amount }}</div>
          </div>
        </div>
      </div>
      <div class="bets-info bet-data flex flex-col bg-slate-800 mt-1 h-full">
        <div class="b-total flex flex-row justify-between items-center">
          <div class="flex flex-row items-center justify-center">
            <div class="bet-icon hidden">
              <img
                class="w-6 h-6"
                src="@/assets/images/roulette/coin-bonus.png"
              />&nbsp;&nbsp;&nbsp;
            </div>
            {{ roulette_bets_info.bonus.total }} Bets Total
          </div>
          <div class="flex flex-row">
            <div class="text-orange-400">
              <font-awesome-icon icon="fa-solid fa-coins" />
            </div>
            <span class="ml-1">{{ roulette_bets_info.bonus.value }}</span>
          </div>
        </div>
        <div class="b-players flex flex-col">
          <div
            v-for="(bet, index) in bonus_bets"
            :key="index"
            class="flex flex-row items-center justify-between"
          >
            <div class="flex flex-row items-center">
              <img class="w-6 h-6 user-avatar" :src="bet.avatar" alt="User Avatar" />
              <span class="cmt-6">{{ bet.name }}</span>
            </div>
            <div class="cmt-6">{{ bet.amount }}</div>
          </div>
        </div>
      </div>
      <div class="bets-info bet-data flex flex-col bg-slate-800 mt-1 h-full">
        <div class="b-total flex flex-row justify-between items-center">
          <div class="flex flex-row items-center justify-center">
            <div class="bet-icon hidden">
              <img
                class="w-6 h-6"
                src="@/assets/images/roulette/coin-t.png"
              />&nbsp;&nbsp;&nbsp;
            </div>
            {{ roulette_bets_info.t.total }} Bets Total
          </div>
          <div class="flex flex-row">
            <div class="text-orange-400">
              <font-awesome-icon icon="fa-solid fa-coins" />
            </div>
            <span class="ml-1">{{ roulette_bets_info.t.value }}</span>
          </div>
        </div>
        <div class="b-players flex flex-col">
          <div
            v-for="(bet, index) in t_bets"
            :key="index"
            class="flex flex-row items-center justify-between"
          >
            <div class="flex flex-row items-center">
              <img class="w-6 h-6 user-avatar" :src="bet.avatar" alt="User Avatar" />
              <span class="cmt-6">{{ bet.name }}</span>
            </div>
            <div class="cmt-6">{{ bet.amount }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Wheel from "./Wheel.vue";
import "@/assets/css/roulette.css";

export default {
  name: "RouletteMain",
  components: {
    Wheel,
  },
  data() {
    return {
      bet: "",
    };
  },
  methods: {
    place_bet(coin) {
      this.$store.dispatch("roulette", {
        type: "place_bet",
        amount: this.bet,
        coin,
      });
    },
    action(type) {
      if (this.bet === "" || !this.bet) this.bet = 0;
      switch (type) {
        case "clear": {
          this.bet = 0;
          break;
        }

        case "+10": {
          this.bet = parseInt(this.bet) + 10;
          break;
        }

        case "+100": {
          this.bet = parseInt(this.bet) + 100;
          break;
        }

        case "+1000": {
          this.bet = parseInt(this.bet) + 1000;
          break;
        }

        case "+10000": {
          this.bet = parseInt(this.bet) + 10000;
          break;
        }

        case "1/2": {
          this.bet = parseInt(this.bet) / 2;
          break;
        }

        case "x2": {
          this.bet = parseInt(this.bet) * 2;
          break;
        }

        case "max": {
          this.bet = parseInt(this.user.points);
          break;
        }
      }
    },
    roulette_icon(icon) {
      return `${this.env.backend_url}/assets/roulette/coin-${icon}.png`;
    },
  },
  computed: {
    roulette_history() {
      return this.$store.state.website.games.roulette.history;
    },
    roulette_style() {
      return this.$store.state.website.games.roulette.animation;
    },
    roulette_state() {
      return this.$store.state.website.games.roulette.state;
    },
    roulette_seconds() {
      return parseFloat(
        this.$store.state.website.games.roulette.seconds / 100
      ).toFixed(2);
    },
    roulette_connected() {
      return this.$store.state.website.games.roulette.connected;
    },
    roulette_lastrolls() {
      return this.$store.state.website.games.roulette.last_rolls;
    },
    roulette_secret() {
      return this.$store.state.website.games.roulette.secret;
    },
    roulette_id() {
      return this.$store.state.website.games.roulette.id;
    },
    ct_bets() {
      let filtered_bets = this.$store.state.website.games.roulette.bets.filter(
        (bet) => {
          return bet.type === "ct";
        }
      );
      const groups = filtered_bets.reduce((groups, item) => {
        const group = groups[item.name] || [];
        group.push(item);
        groups[item.name] = group;
        return groups;
      }, {});

      let new_groups = [];

      for (let x in groups) {
        let group = groups[x];
        let amount_bet = group
          .map((gr) => {
            return gr.amount;
          })
          .reduce((partialSum, a) => partialSum + a, 0);
        new_groups.push({
          betId: group[0].betId,
          userId: group[0].userId,
          name: group[0].name,
          avatar: group[0].avatar,
          amount: amount_bet,
          type: group[0].type,
        });
      }

      return new_groups;
    },
    bonus_bets() {
      let filtered_bets = this.$store.state.website.games.roulette.bets.filter(
        (bet) => {
          return bet.type === "bonus";
        }
      );
      const groups = filtered_bets.reduce((groups, item) => {
        const group = groups[item.name] || [];
        group.push(item);
        groups[item.name] = group;
        return groups;
      }, {});

      let new_groups = [];

      for (let x in groups) {
        let group = groups[x];
        let amount_bet = group
          .map((gr) => {
            return gr.amount;
          })
          .reduce((partialSum, a) => partialSum + a, 0);
        new_groups.push({
          betId: group[0].betId,
          userId: group[0].userId,
          name: group[0].name,
          avatar: group[0].avatar,
          amount: amount_bet,
          type: group[0].type,
        });
      }

      return new_groups;
    },
    t_bets() {
      let filtered_bets = this.$store.state.website.games.roulette.bets.filter(
        (bet) => {
          return bet.type === "t";
        }
      );
      const groups = filtered_bets.reduce((groups, item) => {
        const group = groups[item.name] || [];
        group.push(item);
        groups[item.name] = group;
        return groups;
      }, {});

      let new_groups = [];

      for (let x in groups) {
        let group = groups[x];
        let amount_bet = group
          .map((gr) => {
            return gr.amount;
          })
          .reduce((partialSum, a) => partialSum + a, 0);
        new_groups.push({
          betId: group[0].betId,
          userId: group[0].userId,
          name: group[0].name,
          avatar: group[0].avatar,
          amount: amount_bet,
          type: group[0].type,
        });
      }

      return new_groups;
    },
    roulette_bets_info() {
      return {
        ct: {
          total: this.ct_bets.length,
          value: this.ct_bets
            .map((bet) => {
              return bet.amount;
            })
            .reduce((partialSum, a) => partialSum + a, 0),
        },
        bonus: {
          total: this.bonus_bets.length,
          value: this.bonus_bets
            .map((bet) => {
              return bet.amount;
            })
            .reduce((partialSum, a) => partialSum + a, 0),
        },
        t: {
          total: this.t_bets.length,
          value: this.t_bets
            .map((bet) => {
              return bet.amount;
            })
            .reduce((partialSum, a) => partialSum + a, 0),
        },
      };
    },
    getWheelClass() {
      return this.roulette_state == 0
        ? `wheel wheel-fade transition-all`
        : `wheel transition-all`;
    },
    selectorClass() {
      return this.roulette_state === 1
        ? `selector transition-all`
        : `selector transition-all opacity-0`;
    },
    rollingInClass() {
      return this.roulette_state === 0
        ? `rolling-in transition-all`
        : `rolling-in transition-all opacity-0`;
    },
    env() {
      return this.$store.state.env;
    },
    user() {
      return this.$store.state.user;
    },
    socket() {
      return this.$store.state.socket;
    },
  },
};
</script>
