<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="show"
      id="staticModal"
      data-modal-backdrop="static"
      tabindex="-1"
      aria-hidden="true"
      class="modal-main fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full modal-centered modal-animation"
    >
      <div @click="closeModal" class="modal-overlay z-1"></div>
      <div class="relative w-full h-full max-w-2xl md:h-auto z-2">
        <!-- Modal content -->
        <div class="relative bg-gray-700 rounded-lg shadow">
          <!-- Modal header -->
          <div
            class="flex items-start justify-between p-4 border-b rounded-t border-gray-600"
          >
            <slot name="header"></slot>
            <button
              type="button"
              class="text-gray-400 bg-transparent hover:bg-gray-600 hover:text-white rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              @click="closeModal"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body p-6 space-y-6">
            <slot name="body"></slot>
          </div>
          <!-- Modal footer -->
          <div
            class="flex justify-end items-center p-6 space-x-2 border-t border-gray-600 rounded-b"
          >
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ModalComp",
  props: ["show", "modal"],
  methods: {
    closeModal() {
      this.$store.dispatch("set_modal", { modal: this.modal, value: false });
    },
  },
};
</script>

<style>
</style>