<template>
  <div class="gm-wrapper text-white affs">
    <h2>Profile</h2>
    <br />
    <div class="w-full">
      
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileMainComp",
  methods: {
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {

  },
  data() {
    return {
      test: 1
    };
  },
};
</script>