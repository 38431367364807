<template>
  <div class="gm-wrapper text-white affs">
    <h2>Affiliates</h2>
    <br />
    <div class="flex topa">
      <div class="box">
        <div>Tier</div>
        <div>
          <font-awesome-icon
            icon="fa-solid fa-trophy"
            :class="tiers[current_tier].class"
          />&nbsp;{{ tiers[current_tier].name }}
        </div>
      </div>
      <div class="box">
        <div>Commission</div>
        <div>{{ parseFloat(tiers[current_tier].commission).toFixed(2) }}%</div>
      </div>
      <div class="box">
        <div>Available Earnings</div>
        <div>
          <div class="text-yellow-500">
            <font-awesome-icon icon="fa-solid fa-coins" />
          </div>
          <div>{{ this.format_number(user.aff_earnings) }}</div>
        </div>
      </div>
      <div class="box">
        <div>Total Deposits</div>
        <div>
          <div class="text-yellow-500">
            <font-awesome-icon icon="fa-solid fa-coins" />
          </div>
          <div>{{ this.format_number(user.aff_deposits) }}</div>
        </div>
      </div>
    </div>
    <div class="w-full">
      <div class="flex flex-col items-center mb-6">
        <button
          type="button"
          class="w-100 m-auto flex justify-center items-center mt-5 text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
          @click="collect"
        >
          Collect Earnings
        </button>
      </div>
    </div>
    <div class="w-full mt-10">
      <div class="flex flex-col items-center mb-6">
        <div>
          <label
            class="block text-gray-300 font-bold text-right mb-2 pr-4 text-xl"
          >
            Set your Referral Code:
          </label>
        </div>
        <div class="w-2/4 ref_code_input">
          <input
            class="ref_code flex m-auto bg-gray-200 appearance-none border-2 border-gray-200 rounded w-2/5 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
            type="text"
            placeholder="Insert your referral code"
            v-model="referral_code"
          />
        </div>
        <div class="w-2/4">
          <button
            type="button"
            class="w-100 m-auto flex justify-center items-center mt-5 text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-green-600 hover:bg-green-700 focus:ring-green-800"
            @click="update_referral"
          >
            Save
          </button>
        </div>
      </div>
    </div>
    <div class="w-full">
      <div class="flex flex-col items-center mb-6">
        <div class="block text-gray-300 font-bold text-right mb-2 pr-4">
          Current referral code: {{ your_referral_code }}
        </div>
      </div>
    </div>
    <div class="w-full">
      <h3 class="font-medium">Active referrers (last 14 days)</h3>

      <div class="flex flex-col">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8 aff-full">
          <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
            <div class="overflow-hidden">
              <table class="min-w-full text-left text-sm font-light">
                <thead class="border-b font-medium dark:border-neutral-500">
                  <tr>
                    <th scope="col" class="px-6 py-4">Username</th>
                    <th scope="col" class="px-6 py-4"># of Deposits</th>
                    <th scope="col" class="px-6 py-4">Deposits Value</th>
                    <th scope="col" class="px-6 py-4">Last Deposit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(data, user_id) in active_referrers"
                    :key="user_id"
                    class="border-b dark:hover-neutral-500 aff-data"
                  >
                    <td>{{ show_first_letters(get_username(data)) }}</td>
                    <td>{{ data.length }}</td>
                    <td>{{ get_total_sum(data) }}</td>
                    <td>{{ last_deposit_time(data) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AffiliatesComp",
  methods: {
    collect() {
      this.$store.dispatch("user_collect_earnings");
    },
    update_referral() {
      this.$store.dispatch("user_update_referral_code", this.referral_code);
    },
    get_active_referrers() {
      this.$store.dispatch("user_get_active_referrers");
    },
    get_current_tier() {
      const tiers = [
        0, 50000, 100000, 250000, 600000, 1500000, 2800000, 5000000,
      ];
      let current_tier = 1;
      tiers.map((tier_deposits, index) => {
        if (this.aff_deposits >= tier_deposits)
          current_tier = parseInt(index) + 1;
      });
      this.current_tier = current_tier;
    },
    get_total_sum(data) {
      let sum = 0;
      for (let x in data) {
        sum = parseInt(sum) + parseInt(data[x].amount);
      }
      return this.format_number(sum);
    },
    last_deposit_time(data) {
      return new Date(
        Date.now() -
          data.sort((a, b) => {
            return a.time - b.time;
          })[0].time
      ).toLocaleString();
    },
    get_username(data) {
      return data[0].username;
    },
    format_number(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    show_first_letters(str) {
      return str.length <= 3 ? str : str.slice(0, 4) + "...";
    },
  },
  computed: {
    your_referral_code() {
      return Object.keys(this.$store.state.user).length > 0
        ? !this.$store.state.user.aff_code ||
          typeof this.$store.state.user.aff_code !== "string"
          ? "code not set"
          : this.$store.state.user.aff_code
        : "N/A";
    },
    aff_deposits() {
      return typeof this.$store.state.user.aff_deposits !== "number"
        ? 0
        : this.$store.state.user.aff_deposits;
    },
    active_referrers() {
      return this.$store.state.active_referrers;
    },
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {
    this.get_current_tier();
    setTimeout(() => {
      this.get_active_referrers();
    }, 1000);
  },
  data() {
    return {
      referral_code: "",
      current_tier: 1,
      tiers: {
        1: {
          class: "tier-iron",
          commission: 1.0,
          name: "Iron",
        },
        2: {
          class: "tier-bronze",
          commission: 1.25,
          name: "Bronze",
        },
        3: {
          class: "tier-silver",
          commission: 1.5,
          name: "Silver",
        },
        4: {
          class: "tier-gold",
          commission: 1.75,
          name: "Gold",
        },
        5: {
          class: "tier-platinum",
          commission: 2.0,
          name: "Platinum",
        },
        6: {
          class: "tier-diamond",
          commission: 2.5,
          name: "Diamond",
        },
        7: {
          class: "tier-master",
          commission: 3.0,
          name: "Master",
        },
        8: {
          class: "tier-challenger",
          commission: 5.0,
          name: "Challenger",
        },
      },
    };
  },
};
</script>