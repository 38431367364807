<template>
  <div class="nav-main bg-gray-800 flex">
    <div class="nav-logo h-34 w-1/6 flex m-auto p-5">
      <img class="h-fit" src="@/assets/logo.png" alt="XFlip Logo" />
    </div>
    <div class="nav-wrapper flex flex-col w-full h-28">
      <div
        class="top-wrapper p-5 bg-gray-900 w-full h-1/4 flex flex-row items-center pr-5 pl-5 uppercase font-bold justify-between">
        <div class="flex w-1/2 flex-row items-center justify-evenly">
          <div v-for="(item, index) in topitems" :key="index" :class="curr_route(item.route)
            ? classes.topitem + ' text-green-500'
            : classes.topitem
            " @click="checkTopItem(item)">
            <div v-if="Object.keys(user).length !== 0 && item.loggedin !== false && !item.admin">
              <font-awesome-icon :icon="item.icon" />
              <span class="ml-1">{{ item.text }}</span>
            </div>
            <div v-else-if="Object.keys(user).length >= 0 && item.loggedin === false && !item.admin">
              <font-awesome-icon :icon="item.icon" />
              <span class="ml-1">{{ item.text }}</span>
            </div>
            <div
              v-else-if="Object.keys(user).length >= 0 && item.loggedin === true && (item.admin && user.rank === 100)"
              style="color: red">
              <font-awesome-icon :icon="item.icon" />
              <span class="ml-1">{{ item.text }}</span>
            </div>
          </div>
        </div>
        <div class="flex w-1/2 flex-row items-center justify-end">
          <div class="cursor-pointer">
            <img class="w-4 h-4" src="@/assets/images/icons/discord.svg" alt="Discord Icon" />
          </div>
          <div class="ml-3 cursor-pointer">
            <img class="w-4 h-4" src="@/assets/images/icons/twitter.svg" alt="Twitter Icon" />
          </div>
        </div>
      </div>
      <div class="bottom-wrapper w-full h-3/4 flex flex-row items-center pr-5 pl-5 font-bold justify-between">
        <div class="w-1/2 flex flex-row justify-around">
          <router-link v-for="(item, index) in bottomitems" :key="index" :class="curr_route(item.route)
            ? classes.bottomitem + ' text-green-500'
            : classes.bottomitem
            " :to="item.route">
            <font-awesome-icon :icon="item.icon" />
            <span class="ml-2">{{ item.text }}</span>
          </router-link>
        </div>
        <div class="w-1/2 flex flex-row justify-end">
          <div v-if="Object.keys(user).length === 0" @click="Login" :class="classes.bottomitem">
            <font-awesome-icon icon="fa-solid fa-right-to-bracket" />
            <span class="ml-2">Auth</span>
          </div>
          <div v-else>
            <div class="profile flex flex-row justify-center items-center">
              <div class="deposit-btn" @click="showDeposit">
                <font-awesome-icon icon="fa-solid fa-plus-square" />
                <span class="ml-1">DEPOSIT</span>
              </div>
              <div class="withdraw-btn" @click="showWithdraw">
                <font-awesome-icon icon="fa-solid fa-minus-square" />
              </div>
              <div class="flex flex-row items-center justify-center">
                <img class="user_avatar" :src="user.avatar" alt="User Profile" />
                <div class="flex flex-col justify-center min-w-[110px]">
                  <div class="text-gray-300 text-center">{{ user.username }}</div>
                  <div class="justify-around text-gray-400/40 flex flex-row items-center">
                    <div class="flex flex-row">
                      <div class="text-yellow-500">
                        <font-awesome-icon icon="fa-solid fa-coins" />
                      </div>
                      &nbsp;{{ user.points }}&nbsp;
                    </div>
                    <div class="transition-colors hover:text-green-500 cursor-pointer" @click="logout">
                      <font-awesome-icon icon="fa-solid fa-right-from-bracket" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/nav.css";

export default {
  name: "NavbarComp",
  methods: {
    showDeposit() {
      this.$store.dispatch("set_modal", { modal: "deposit", value: true });
    },
    showWithdraw() {
      this.$store.dispatch("set_modal", { modal: "withdraw", value: true });
    },
    Login() {
      this.$store.dispatch("set_modal", { modal: "registration", value: true });
    },
    logout() {
      this.$store.dispatch("logout");
    },
    curr_route(r) {
      if (r === this.$route.path) return true;
      return false;
    },
    openModal(modal) {
      this.$store.dispatch("set_modal", {
        modal,
        value: true,
      });
    },
    toRoute(route) {
      this.$router.push(route);
    },
    checkTopItem(item) {
      if (item.modal) return this.openModal(item.modal);
      this.toRoute(item.route);
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
      classes: {
        topitem:
          "flex-row text-gray-400/40 transition-colors hover:text-green-500 cursor-pointer h-fit topitem",
        bottomitem:
          "flex-row text-gray-400/40 transition-colors hover:text-green-500 cursor-pointer uppercase",
      },
      topitems: [
        {
          icon: "fa-solid fa-info-circle",
          text: "FAQ",
          modal: "faq",
          loggedin: false,
        },
        {
          icon: "fa-solid fa-note-sticky",
          text: "TOS",
          modal: "tos",
          loggedin: false,
        },
        {
          icon: "fa-solid fa-check-circle",
          text: "Provably Fair",
          route: "/fair",
          loggedin: false,
        },
        {
          icon: "fa-solid fa-gift",
          text: "Free Coins",
          modal: "freecoins",
          loggedin: true,
        },
        {
          icon: "fa-solid fa-user-plus",
          text: "Affiliates",
          route: "/affiliates",
          loggedin: true,
        },
        {
          icon: "fa-solid fa-user",
          text: "Admin",
          route: "/admin",
          loggedin: true,
          admin: true
        }
      ],
      bottomitems: [
        {
          icon: "fa-solid fa-circle-notch",
          text: "Roulette",
          route: "/roulette",
        },
        {
          icon: "fa-solid fa-bomb",
          text: "Minesweeper",
          route: "/mines",
        }
      ],
    };
  },
};
</script>