<template>
  <Modal :show="show" :modal="modal">
    <template v-slot:header>
      <h3 class="text-xl font-semibold text-white">Chat Rules</h3>
    </template>
    <template v-slot:body>
      <div class="font-bold text-white">No Begging</div>
      <span class="text-white">
        Asking for limiteds or "slides" will result in a permanent mute.
      </span>
      <div class="font-bold text-white">Scamming</div>
      <span class="text-white">
        Do not try to scam or lure others, includes flip help services.
      </span>
      <div class="font-bold text-white">Advertisements</div>
      <span class="text-white">
        No advertising sales or trading of items in the chat, this includes
        items in the shop.
      </span>
      <div class="font-bold text-white">Spam</div>
      <span class="text-white">Do not excessively spam the chat.</span>
    </template>
    <template v-slot:footer>
      <button
        type="button"
        class="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-orange-600 hover:bg-orange-700 focus:ring-orange-800"
        @click="close"
      >
        I Agree
      </button>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "ChatRulesModalComp",
  components: {
    Modal,
  },
  data() {
    return {
      modal: "rules",
    };
  },
  methods: {
    close() {
      this.$store.dispatch("set_modal", {
        modal: this.modal,
        value: false,
      });
    },
  },
  computed: {
    show() {
      return this.$store.state.modals[this.modal];
    },
  },
};
</script>
