<template>
  <Modal :show="show" :modal="modal">
    <template v-slot:header>
      <h3 class="text-xl font-semibold text-white">
        <font-awesome-icon icon="fa-solid fa-plus-square" /> Deposit
      </h3>
    </template>
    <template v-slot:body>
      <div class="text-white">
        <div class="w-full">
          <label>Deposit Amount (in OSRS Gold)</label>
          <input class="w-full transition-colors hover:bg-gray-800" type="text" v-model="deposit_amount" @input="convertToCoins" />
          <br><br>
          <p>Converted Amount: {{ converted_amount }} coins</p>
          <p>Final Amount after 5% fee: {{ final_amount }} coins</p>
          <br><br>
          <button class="deposit-btn" @click="request">Request</button>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex flex-row justify-start w-full"></div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "DepositModal",
  components: {
    Modal,
  },
  data() {
    return {
      method_selected: false,
      modal: "deposit",
      deposit_amount: 0,
      converted_amount: 0,
      final_amount: 0
    };
  },
  methods: {
    convertToCoins() {
      const amount = this.deposit_amount.toLowerCase();
      if (amount.includes('b')) {
        this.converted_amount = parseFloat(amount) * 1000 * 1000;
      } else if (amount.includes('m')) {
        this.converted_amount = parseFloat(amount) * 1000;
      } else {
        this.converted_amount = parseFloat(amount) / 1000000 * 1000;
      }
      this.calculateFinalAmount();
    },
    calculateFinalAmount() {
      this.final_amount = this.converted_amount * 0.95;
    },
    request() {
      this.$store.dispatch('request_deposit', {
        amount: this.final_amount
      });
    }
  },
  watch: {
    show() {
      // reset everything if modal closes
      if (!this.$store.state.modals[this.modal]) {
        this.deposit_amount = 0;
        this.converted_amount = 0;
        this.final_amount = 0;
      }
    }
  },
  computed: {
    show() {
      return this.$store.state.modals[this.modal];
    }
  }
};
</script>
