<template>
  <Modal :show="show" :modal="modal">
    <template v-slot:header>
      <h3 class="text-xl font-semibold text-white">
        Frequently Asked Questions
      </h3>
    </template>
    <template v-slot:body>
  <div class="font-bold text-white">What is the deposit fee?</div>
  <p class="text-white">
    The deposit fee is 5% of the Old School Runescape© (OSRS) Gold.
  </p>
  <div class="font-bold text-white">How much is 1000 coins worth on the website?</div>
  <p class="text-white">
    1000 coins on our website are worth 1 million Old School Runescape© (OSRS) Gold.
  </p>
  <div class="font-bold text-white">Do you accept Runescape© 3 gold?</div>
  <p class="text-white">
    No, we currently do not accept Runescape© 3 gold.
  </p>
  <div class="font-bold text-white">What are the withdrawal options?</div>
  <p class="text-white">
    You can withdraw only in Old School RuneScape© (OSRS) gold. We do not accept any form of payment, including cryptocurrency.
  </p>
    </template>
    <template v-slot:footer> </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "FaqModalComp",
  components: {
    Modal,
  },
  data() {
    return {
      modal: "faq",
    };
  },
  methods: {
    showTos() {
      this.$store.dispatch("set_modal", { modal: "login", value: false });
      this.$store.dispatch("set_modal", { modal: "faq", value: true });
    },
  },
  computed: {
    show() {
      return this.$store.state.modals[this.modal];
    },
  },
};
</script>
