<template>
  <div v-for="n in 17" class="row" :key="n">
    <div class="card">
      <img src="@/assets/images/roulette/coin-ct.png" alt="card" />
    </div>
    <div class="card">
      <img src="@/assets/images/roulette/coin-t.png" alt="card" />
    </div>
    <div class="card">
      <img src="@/assets/images/roulette/coin-ct.png" alt="card" />
    </div>
    <div class="card">
      <img src="@/assets/images/roulette/coin-t.png" alt="card" />
    </div>
    <div class="card">
      <img src="@/assets/images/roulette/coin-ct.png" alt="card" />
    </div>
    <div class="card">
      <img src="@/assets/images/roulette/coin-t.png" alt="card" />
    </div>
    <div class="card">
      <img src="@/assets/images/roulette/coin-ct.png" alt="card" />
    </div>
    <div class="card">
      <img src="@/assets/images/roulette/coin-bonus.png" alt="card" />
    </div>
    <div class="card">
      <img src="@/assets/images/roulette/coin-t.png" alt="card" />
    </div>
    <div class="card">
      <img src="@/assets/images/roulette/coin-ct.png" alt="card" />
    </div>
    <div class="card">
      <img src="@/assets/images/roulette/coin-t.png" alt="card" />
    </div>
    <div class="card">
      <img src="@/assets/images/roulette/coin-ct.png" alt="card" />
    </div>
    <div class="card">
      <img src="@/assets/images/roulette/coin-t.png" alt="card" />
    </div>
    <div class="card">
      <img src="@/assets/images/roulette/coin-ct.png" alt="card" />
    </div>
    <div class="card">
      <img src="@/assets/images/roulette/coin-t.png" alt="card" />
    </div>
  </div>
</template>

<script>
export default {
  name: "RouletteWheelComp",
};
</script>