<template>
  <div class="gm-wrapper">
    <div class="minesweeper">
      <div class="bet-info flex flex-col">
        <div class="b-text">Bet Amount:</div>
        <div class="flex betting-input">
          <div class="text-yellow-500">
            <font-awesome-icon icon="fa-solid fa-coins" />
          </div>
          <input v-model="amount" type="number" placeholder="Enter bet amount..." />
        </div>
      </div>
      <div class="bet-info flex flex-col">
        <div class="b-text">Mines:</div>
        <div class="flex mine-mines">
          <input v-model="mines" @input="changeMines" type="number" placeholder="Enter mines amount..." max="24"
            min="1" />
        </div>
      </div>
      <div v-if="game.state === 'idle'" class="mt-5 w-2/12 bet-info flex flex-col">
        <button
          class="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
          @click="bet">
          Bet
        </button>
      </div>
      <div v-else class="mt-5 w-2/12 bet-info flex flex-col">
        <button
          class="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
          @click="bet">
          Cashout +{{ parseInt(game.amount) }}
        </button>
      </div>
      <div class="game-info">
        <div class="game-tiles">
          <div class="wrapper">
            <div @click="discoverTile(index)" v-for="(tile, index) in game.tiles" :class="assertClass(tile)" :key="index">
              <div v-if="tile < 0" class="text-red-800 bomb">
                <font-awesome-icon icon="fa-solid fa-bomb" />
              </div>
              <div v-else-if="typeof tile === 'string'" class="success" v-html="'+' + parseInt(tile)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/minesweeper.css";

export default {
  name: "HomeMain",
  data() {
    return {
      amount: 0,
      mines: 3
    };
  },
  methods: {
    changeMines(e) {
      if (e.target.value < 1) this.mines = 1;
      else if (e.target.value > 24) this.mines = 24;
    },
    discoverTile(tile_index) {
      const actual_tile = parseInt(tile_index + 1);
      if (actual_tile < 1 || actual_tile > 25) return;
      this.$store.dispatch("minesweeper", {
        type: "discover_tile",
        tile: parseInt(tile_index + 1),
      });
    },
    bet() {
      this.$store.dispatch("minesweeper", { type: "place_bet", amount: this.amount, mines: this.mines });
    },
    assertClass(tile) {
      let tileType = "normalTile";
      if (tile < 0) tileType = "bombTile";
      else if (typeof tile === "string") tileType = "successTile";
      return `game-tile ${tileType}`;
    },
  },
  computed: {
    game() {
      return this.$store.state.website.games.minesweeper;
    },
  },
  mounted() { },
};
</script>
