<template>
  <div class="gm-wrapper text-white">
    <h2><font-awesome-icon icon="fa-solid fa-check-circle" /> Provably Fair</h2>
    <br />
    <h4><strong>Roulette</strong></h4>
    <p>Our innovative, provably fair system guarantees the integrity of every round.</p>
    <br />
    <p><strong>How It Works</strong></p>
    <p>1. <strong>Secret Generation</strong>: At the beginning of each round, a secret is generated and displayed on the roulette page.</p>
    <p>2. <strong>Bet Encoding</strong>: At the end of the round, all bets are converted from JSON format to a base64-encoded string.</p>
    <p>3. <strong>Hash Creation</strong>: Using the 'md5' library, a round hash is generated by combining the secret with the base64-encoded bets.</p>
    <p>4. <strong>Digit Extraction</strong>: Non-numeric characters are removed from the round hash, leaving only digits.</p>
    <p>5. <strong>Modulo Operation</strong>: The resulting integer is subjected to a modulo operation with 15, producing a number between 0 and 14.</p>
    <ul>
      <li><strong>0</strong>: Green</li>
      <li><strong>1-7</strong>: Red</li>
      <li><strong>8-14</strong>: Black</li>
    </ul>
    <p>This process ensures that the outcome is dynamic and tamper-proof, providing a fair and transparent gaming experience.</p>
  </div>
</template>

<script>
export default {
  name: "FairComp",
};
</script>
