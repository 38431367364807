// add toast notifications and default styling
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const ToastOptions = {
  autoClose: 3000,
  theme: "colored",
  position: toast.POSITION.BOTTOM_RIGHT,
};

export const Toast = (type, msg) => {
  toast[type](msg, ToastOptions);
};
